const MainPageSkeleton = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        background: '#6D31CE',
      }}
    ></div>
  );
};

export default MainPageSkeleton;
